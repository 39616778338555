<template>
    <div class="details_page">
        <el-card class="box-card" v-if="type == '-1'">
            <div class="card_box">
                <h2 class="t_center">{{noticeInfo.noticeTitle}}</h2>
                <p class="t_center">发布时间：{{noticeInfo.noticeAddTime}}</p>
                <div class="ql-snow ql-editor" v-html="noticeInfo.noticeDetail"></div>
            </div>
        </el-card>
        <div v-if="type == '2'">
            <img :src="imgInfo.imagePath" style="width:100%;height:auto;margin-top:25px" alt="">
            <div class="nav_s">
                <h2 class="t_center" style="margin-top:25px">{{imgInfo.imageTitle}}</h2>
                <div class="title">
                    <div class="title_pop">
                    <span>{{boxText}}</span>
                    </div>
                </div>
                <ul class="ul_box">
                    <li><a href="#/">首页</a></li>
                    <li>></li>
                    <li>{{boxText}}</li>
                </ul>
                <div style="clear:both"></div>
                <el-divider content-position="left">{{imgInfo.imageTitle}}详情</el-divider>
                <div class="ql-snow ql-editor" v-html="imgInfo.imageDetail"></div>
            </div>
            <!-- <div style="padding:0 10%">
            </div> -->
        </div>
    </div>
</template>



<script>
export default {
    props:['typeList'],
    data(){
        return{
            type:null,
            noticeInfo:{
                noticeTitle:'',
                noticeAddTime:'',
                noticeContext:'',
                noticeDetail:''
            },
            imgInfo:{
                imageTitle:'',
                imagePath:'',
                imageDetail:''
            },
            boxText:''
        }
    },
    methods:{
        getNoticeDetail(id,type){
            this.axios.get('/notice/queryNoticeById',{params:{id}}).then(res=>{
                this.noticeInfo = res.data.data
            })
        },
        getImgDetail(id){
            this.axios.get('image/queryImageById',{params:{id}}).then(res=>{
                res.data.data.imagePath = this.$store.state.imgUrl + "/images/" + res.data.data.imagePath
                this.imgInfo = res.data.data
                console.log(11111,this.imgInfo)
            })
        }

    },
    mounted(){
        this.type = this.$route.query.type
        if(this.type == '-1'){
            this.getNoticeDetail(this.$route.query.id)
        }else{
            this.getImgDetail(this.$route.query.id)
            let obj = this.typeList.find(val=>{return val.typeId == this.type})
            if(obj !== undefined){
                this.boxText = obj.typeTitle
            }
        }
    }
}
</script>



<style scoped lang="less">
.details_page{
    min-height: 100%;
}
.card_box{
    padding: 75px 20px 0;
}
.t_center{
    text-align: center;
}

.nav_s{
    position: relative;
    padding:0 10%;
}
.title {
  width: 27%;
  height: 90px;
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: -90px;
  padding: 10px;
}
.title .title_pop {
  border: 2px solid #fff;
}
.title .title_pop span {
  color: #fff;
  font-size: 2rem;
  line-height: 66px;
}
.ul_box li{
    float: left;
}
.ul_box li+li{
    margin-left:1%
}
</style>